<template lang="pug">
.expense.pb-5
  AppTitle(label="Gastos")
  .expense__header
    router-link(
      :to="{ name: 'ExpenseNew' }"
      v-slot="{ navigate }"
      custom
    )
      AppButton(label="Nuevo" @click="navigate")
  .expense__content(v-if="expenses.length")
    form(@submit.prevent="onSubmit")
      AppExpense.mb-5(
        v-for="expense in expenses"
        :id="expense.id"
        :name="expense.name"
        :fecha="expense.fecha"
        :emisor="expense.nombre_emisor"
        :total="expense.total"
        :category="expense.category_id[1]"
        v-model:value="expense.status" 
        @delete="onDelete"
      )
      AppButton(label="Guardar")
</template>

<script>
import AppTitle from "@/components/AppTitle";
import AppExpense from "@/components/AppExpense";
import { AppButton } from "gignet-components";
import { onMounted, ref } from "@vue/runtime-core";
import { store } from "@/store";
import { axios } from "@/utils/axios";
import { onError, onSuccess } from "@/utils/toastify";
export default {
  components: { AppTitle, AppButton, AppExpense },
  setup() {
    const expenses = ref([]);

    const onSubmit = async () => {
      let status = expenses.value.find((expense) => expense.status == false);
      if (status) return onError("Confirma todas las facturas");

      store.loading = true;
      try {
        let ids = expenses.value.reduce((acc, el) => [...acc, el.id], []);
        await axios.post("expenses/change", {
          ids,
          status_invoice_expense: "PUBLICADO",
        });
        expenses.value = [];
        onSuccess("Gastos Guardados");
      } catch (error) {
        onError("Intento de Nuevo");
      } finally {
        store.loading = false;
      }
    };

    const onDelete = async (id) => {
      try {
        store.loading = true;
        await axios.post("expenses/change", {
          ids: [id],
          status_invoice_expense: "CANCELADO",
        });
        expenses.value = expenses.value.filter((item) => item.id !== id);
        onSuccess("Gasto eliminado");
      } catch (error) {
        onError("El gasto no fue eliminado");
      } finally {
        store.loading = false;
      }
    };

    onMounted(async () => {
      store.loading = true;
      try {
        const { data } = await axios.get("expenses/invoices", {
          params: { type: "BORRADOR", limit: 0 },
        });
        expenses.value = data.payload.map((item) => ({
          ...item,
          status: false,
        }));
      } catch (error) {
        console.log(error);
      } finally {
        store.loading = false;
      }
    });
    return { expenses, onDelete, onSubmit };
  },
};
</script>

<style lang="scss" scoped>
.expense {
  @apply pb-4;
  &__header {
    @apply mb-4;
    ::v-deep {
      .button {
        @apply w-auto;
      }
    }
  }
}
</style>
