<template lang="pug">
.ribbon(:class="type") {{ label }}
</template>

<script>
import AppRibbon from "gignet-components";
export default {
  components: { AppRibbon },
  props: {
    label: {
      type: String,
      default: "BORRADOR",
    },
    type: {
      type: String,
      default: "BORRADOR",
    },
  },
};
</script>

<style lang="scss">
.ribbon {
  @apply absolute;
  @apply top-4;
  @apply -right-14;
  @apply transform;
  @apply rotate-45;
  @apply w-40;
  font-size: 0.6rem;
  @apply text-center;
  @apply z-10;
  @apply h-6;
  @apply leading-6;
  @apply tracking-wide;
  &.AUTORIZADO {
    @apply bg-green-400;
    @apply text-white;
  }
  &.RECHAZADO {
    @apply bg-red-400;
    @apply text-white;
  }
  &.PUBLICADO {
    @apply bg-yellow-400;
    @apply text-white;
  }
  &.BORRADOR {
    @apply bg-blue-400;
    @apply text-white;
  }
}
</style>
