<template lang="pug">
AppCard.expense
  AppStatusTitle(:type="status" :label="status")  
  .expense__name {{name}}
  AppRow.expense__body
    AppDescription(label="Fecha" :description="dateFormatter(fecha)")
    AppDescription(label="Categoría" :description="category")
    AppDescription(label="Emisor" :description="emisor")
    AppDescription(label="Total" :description="numberFormatter(total)")
    .expense__options(v-if="id")
      AppCheckbox.expense__checkbox(@input="$emit('update:value', $event.target.checked)")
      i(@click="toDetails(id)").uil.uil-eye
      i(@click="$emit('delete', id)").uil.uil-trash-alt
</template>

<script>
import { dateFormatter, numberFormatter } from "@/utils/formatters";
import AppStatusTitle from "@/components/AppStatusTitle";
import { useRouter } from "vue-router";
import AppCheckbox from "@/components/AppCheckbox";
import { AppCard, AppRow, AppDescription } from "gignet-components";
export default {
  components: { AppCard, AppRow, AppDescription, AppCheckbox, AppStatusTitle },
  props: {
    id: {
      type: Number,
    },
    category: {
      type: String,
    },
    name: {
      type: String,
    },
    fecha: {
      type: String,
    },
    emisor: {
      type: String,
    },
    total: {
      type: Number,
    },
    status: {
      type: String,
    },
  },
  emits: ["delete", "update:value"],
  setup() {
    const route = useRouter();
    const toDetails = (id) => {
      route.push({ name: "ExpenseDetails", params: { id } });
    };
    return { dateFormatter, numberFormatter, toDetails };
  },
};
</script>

<style lang="scss" scoped>
.expense {
  &__name {
    @apply text-xs;
    @apply text-bluegray-400;
    @apply mb-2;
    @apply font-medium;
  }
  &__options {
    @apply flex;
    @apply justify-end;
    @apply items-center;
    @apply flex-col;
    @screen md {
      @apply mr-6;
      @apply flex-row;
    }
    i {
      @apply px-2;
      @apply text-lg;
      @apply mx-0;
      @screen md {
        @apply mx-4;
      }
    }
  }
  &__checkbox {
    @apply my-auto;
    @apply ml-2;
    @screen md {
      @apply mx-4;
    }
  }
}
</style>
